* {
  box-sizing: border-box;
  margin: 0em;
  padding: 0em;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a {
  color: #333;
  text-decoration: none;
}

.container {
  padding: 0 1rem;
  max-width: 1000px;
  margin: auto;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.btn:hover {
  background: #666;
}

.App {
  min-height: 100vh;
  background-color: #38618f;
}
