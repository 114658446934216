input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: inherit;
  border-color: #dcf2ff;
}

input[type="checkbox"] {
  display: inline-block;
  position: relative;
  margin: auto;

  width: 1.2em;
  height: 1.2em;
  border: 0.15em solid #dcf2ff;
  border-radius: 3px;
  /* Adjusts the position of the checkboxes on the text baseline */
  vertical-align: -2px;
  /* Set here so that Windows' High-Contrast Mode can override */
  color: #33c191;
}

input[type="checkbox"]::before {
  content: "\2714";
  position: absolute;
  font-size: 1.5em;
  right: -1.7px;
  top: -0.5em;
  visibility: hidden;
}

input[type="checkbox"]:checked::before {
  /* Use `visibility` instead of `display` to avoid recalculating layout */
  visibility: visible;
}

input[type="checkbox"]:disabled {
  border-color: black;
  background: #ddd;
  color: gray;
}
